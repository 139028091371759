export enum SitePsiEnum {
  // Reference value for Time to First Byte (TTFB) in milliseconds
  TTFB_REFERENCE_VALUE = 800,

  // Reference value for Page Speed Score in percentage
  DESKTOP_PAGE_SPEED_SCORE_REFERENCE_VALUE = 85,
  MOBILE_PAGE_SPEED_SCORE_REFERENCE_VALUE = 80,

  // Reference value for Load Time in seconds
  DESKTOP_LOAD_TIME_REFERENCE_VALUE = 3,
  MOBILE_LOAD_TIME_REFERENCE_VALUE = 3.5,
}
