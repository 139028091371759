<script setup>
import VueApexCharts from 'vue3-apexcharts'
import { useTheme } from 'vuetify'
import { getLineChartSimpleConfig } from '@core/libs/apex-chart/apexCharConfig'
import { useDashboardStore } from '@/stores/dashboard'
import { useRootStore } from '@/stores/root'
import { ref } from 'vue'
import { formatNumbersWithComma } from '@/utils/numeralHelper.ts'
import Filters from '@/filters'
import CaseFilters from '@/filters/caseFilters'

const dashboardStore = useDashboardStore()
const rootStore = useRootStore()

const props = defineProps({
  type: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  fullName: {
    type: String,
    required: false,
  },
  colors: {
    type: Array,
    required: false,
  },
  subTypes: {
    type: Array,
    required: false,
  },
  isCommission: {
    type: Boolean,
    required: false,
  },
})

const isLoading = ref(false)

const vuetifyTheme = useTheme()
const balanceChartConfig = computed(() => {
  const current = computed(() => vuetifyTheme.current.value)
  const config = getLineChartSimpleConfig(current.value)
  const colors = computed(() => current.value.colors)
  config.colors = [
    colors.value.warning,
    colors.value.success,
    colors.value.error,
    colors.value.info,
  ]
  return config
})

const series = reactive([])

const getRevenueData = async () => {
  isLoading.value = true
  try {
    const data = await dashboardStore.getRevenueStats(props.type)

    if (data) {
      const categories = dashboardStore.getCategories
      balanceChartConfig.value.xaxis.categories = categories
      balanceChartConfig.value.xaxis.labels.rotate = -20
      balanceChartConfig.value.xaxis.labels.rotateAlways = true
      balanceChartConfig.value.yaxis.labels.formatter = (value) => {
        return `$ ${formatNumbersWithComma(
          props?.isCommission
            ? Filters.currency.centsToDollarsWithoutToFixed(value ?? 0)
            : Filters.currency.centsToDollars(value ?? 0, 0),
        )}`
      }
      balanceChartConfig.value.dataLabels.formatter = (value) => {
        return `$ ${formatNumbersWithComma(
          props?.isCommission
            ? Filters.currency.centsToDollarsWithoutToFixed(value ?? 0)
            : Filters.currency.centsToDollars(value ?? 0, 0),
        )}`
      }
      balanceChartConfig.value.colors =
        props.colors ?? balanceChartConfig.value.colors
      balanceChartConfig.value.tooltip = {
        enabled: true,
        // custom(data) {
        //   return `<div class='bar-chart pa-2'>
        //             <span>${formatNumbers(Filters.currency.centsToDollars(data.series[data.seriesIndex][data.dataPointIndex] ?? 0))}</span>
        //           </div>`
        // },
      }

      if (props?.subTypes) {
        for (let subType of props.subTypes) {
          const seriesData = []
          for (let category of categories) {
            const foundIndex = data?.[props.type]?.categories?.findIndex(
              (c) => c === category,
            )
            if (foundIndex < 0) {
              seriesData.push(0)
            } else {
              seriesData.push(
                data?.[props?.type]?.[subType]?.data?.[foundIndex] ?? 0,
              )
            }
          }
          series.push({
            name: Filters.case.snakeCaseToTitleCase(subType, '_'),
            data: seriesData,
          })
        }
      } else {
        const seriesData = []
        for (let category of categories) {
          const foundIndex = data?.[props.type]?.categories?.findIndex(
            (c) => c === category,
          )
          if (foundIndex < 0) {
            seriesData.push(0)
          } else {
            seriesData.push(data?.[props.type]?.data?.[foundIndex] ?? 0)
          }
        }
        series.push({
          name: Filters.case.snakeCaseToTitleCase(props.type, '_'),
          data: seriesData,
        })
      }
    }
  } catch (e) {
    console.log(e)
    rootStore.showSnackbar(
      'Error in getting revenue data, Please contact support!',
      'error',
    )
  } finally {
    isLoading.value = false
  }
}

onMounted(() => {
  getRevenueData()
})
</script>
<template>
  <VCardItem class="d-flex flex-wrap justify-space-between">
    <VCardTitle
      >{{ name
      }}<span v-if="fullName" class="ml-2">({{ fullName }})</span></VCardTitle
    >
  </VCardItem>
  <section v-if="!isLoading">
    <VueApexCharts
      type="line"
      height="400"
      :options="balanceChartConfig"
      :series="series"
    />
  </section>
  <section v-else style="min-height: 300px" class="text-center mt-4">
    <VProgressCircular indeterminate size="100" />
  </section>
</template>
