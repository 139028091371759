import { setupLayouts } from 'virtual:generated-layouts'
import { createRouter, createWebHistory } from 'vue-router'
import { isUserLoggedIn } from './utils'
import routes from '~pages'
import { useRootStore } from '@/stores/root'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [...setupLayouts(routes)],
  scrollBehavior() {
    return { top: 0 }
  },
})

// Docs: https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards
router.beforeEach(async (to) => {
  const rootStore = useRootStore()
  await rootStore.startFullscreenLoader()
  const isLoggedIn = await isUserLoggedIn()
  if (to.meta.subject === 'Auth') {
    if (isLoggedIn) {
      return '/'
    }
  } else {
    if (!isLoggedIn) {
      return {
        name: 'login',
        query: { to: to.name !== 'index' ? to.fullPath : undefined },
      }
    }
  }
  return true
})

router.afterEach(async () => {
  const rootStore = useRootStore()
  await rootStore.endFullscreenLoader()
})
export default router
