<script setup>
import Sites from '@/views/dashboard/sites/Sites.vue'
const openTab = ref(0)
const tabs = [
  {
    title: 'New Sites',
    type: 'new',
  },
  {
    title: 'Deleted Sites',
    type: 'cancel',
  },
  {
    title: 'Development Sites',
    type: 'development',
  },
  {
    title: 'Staging Sites',
    type: 'staging',
  },
]
</script>
<template>
  <VCol cols="12">
    <VCard class="pt-3">
      <VTabs v-model="openTab" class="v-tabs-pill" align-tabs="center">
        <VTab v-for="tab in tabs" :key="tab.title">
          <span>{{ tab.title }}</span>
        </VTab>
      </VTabs>
      <VCardText>
        <VWindow v-model="openTab" class="disable-tab-transition">
          <VWindowItem v-for="tab in tabs" :key="tab.title + 'content'">
            <sites :type="tab.type" :name="tab.title" />
          </VWindowItem>
        </VWindow>
      </VCardText>
    </VCard>
  </VCol>
</template>
