<script setup lang="ts">
import { formatNumbersWithComma } from "@/utils/numeralHelper.ts";
import Filters from "@/filters";
import { onMounted, ref } from "vue";
import { IStatsKPI, useDashboardStore } from "@/stores/dashboard";
import DashboardService from "@/services/dashboardService";
import { DashboardKPIEnum } from "@/common/enums/dashboardKPIEnum";

const props = defineProps<{
  title: string;
  color: string;
  stats?: number | null;
  icon: string;
  cardKey: DashboardKPIEnum;
  action?: boolean;
  to?: string;
  isRevenue?: boolean;
  isCommission?: boolean;
}>();
const dashboardStore = useDashboardStore();
const loading = ref(false);
const mouseEntered = ref(false);
const fetchKPIData = async () => {
  loading.value = true;
  try {
    const data = await DashboardService.getDashboardStats(props.cardKey);
    dashboardStore.setStatsByKey(props.cardKey, data?.value);
  } finally {
    loading.value = false;
  }
};
watchEffect(async () => {
  dashboardStore.getStatsByKey(props.cardKey)?.stats === null &&
    props.cardKey &&
    (await fetchKPIData());
});
</script>

<template>
  <VCard
    @mouseenter="mouseEntered = true"
    @mouseleave="mouseEntered = false"
    :class="{ 'bg-primary': mouseEntered && action }"
    :loading="loading"
  >
    <VCardText
      class="d-flex flex-column align-center justify-center"
      @click="action && to ? $router.push(to) : ''"
    >
      <VAvatar
        v-if="icon"
        size="42"
        variant="tonal"
        :color="mouseEntered && action ? 'white' : color"
      >
        <VIcon :icon="icon" size="24" />
      </VAvatar>

      <h6
        class="text-h6 font-weight-semibold my-2"
        :class="{ 'text-white': mouseEntered && action }"
      >
        <span v-if="isRevenue">
          $
          {{
            formatNumbersWithComma(Filters.currency.centsToDollars(Number(stats || 0), 0))
          }}
        </span>
        <span v-else-if="isCommission">
          $
          {{
            Filters.currency.centsToDollarsWithoutToFixed(Number(stats || 0))
          }}
        </span>
        <span v-else>{{ stats || 0 }}</span>
      </h6>
      <span
        class="text-body-2"
        :class="{ 'text-white': mouseEntered && action }"
        >{{ title }}</span
      >
    </VCardText>
  </VCard>
</template>