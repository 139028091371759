import moment, { MomentInput } from 'moment'

const DateFilters = {
  MonthDayYear(value: string) {
    return moment.utc(value).format('MMM D, YYYY')
  },
  Standard(value: string) {
    return moment(value).format('MM/DD/YYYY')
  },
  StandardWithTime(value: string) {
    return moment(value).format('MM/DD/YYYY - hh:mm:ss A')
  },
  MDT(value: string) {
    return moment(value).format('LLLL')
  },
  RelativeTime(value: string) {
    const dateNow = new Date()
    const date = new Date(value)
    if (dateNow.getDay() === date.getDay()) {
      return moment(value).startOf('hour').fromNow()
    } else if (dateNow.getDay() - 1 === date.getDay()) {
      return 'Yesterday'
    } else {
      return this.MonthDayYear(value)
    }
  },
  FromNow(value: MomentInput) {
    return moment(value).fromNow()
  },
}

export default DateFilters
