const CurrencyFilters = {
  centsToDollars: (cents: number, floatPoint = 2): string => {
    return parseFloat((cents / 100).toString()).toFixed(floatPoint)
  },
  centsToUSDString: (cents: number): string => {
    return `$ ${parseFloat((cents / 100).toString()).toFixed(2)} USD`
  },
  centsToDollarsWithoutToFixed: (cents: number): string => {
    return (cents / 100).toString()
  },
}

export default CurrencyFilters
