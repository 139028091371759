<script setup>
import { useTheme } from 'vuetify'
import { getDonutChartConfig } from '@core/libs/apex-chart/apexCharConfig'
import { computed } from 'vue'

const props = defineProps({
  value: {
    type: [String, Number],
    required: true,
  },
  label: {
    type: String,
    required: false,
  },
  labelValue: {
    type: [String, Number],
    required: false,
  },
  color: {
    type: String,
    default: 'primary',
  },
  height: {
    type: Number,
    default: 110,
  },
})
const vuetifyTheme = useTheme()
const expenseRationChartConfig = computed(() => {
  const config = getDonutChartConfig(vuetifyTheme.current.value)
  config.labels = [props.label]
  config.stroke.width = 0
  // config.stroke.color = vuetifyTheme.current.value.colors["on-primary"]
  config.dataLabels = {
    enabled: false,
  }
  config.plotOptions.pie.donut.labels.total.label = props.label
  config.plotOptions.pie.donut.labels.total.fontSize = '0.8rem'
  config.plotOptions.pie.donut.labels.value.fontSize = '0.8rem'
  config.plotOptions.pie.donut.labels.total.formatter = () => {
    return props.value
  }
  config.fill = {
    type: 'gradient',
  }
  config.legend.show = false
  return config
})

const series = [props.value]
</script>

<template>
  <VProgressCircular
    class="mt-2"
    :color="props.color"
    :size="props.height"
    :model-value="props.value ?? '--'"
  >
    <div class="d-flex justify-center align-center flex-column">
      <span>
        <strong>
          {{ props.labelValue ?? props.value }}
        </strong>
      </span>

      <span v-if="props.label" class="text-sm-caption">
        {{ props.label }}
      </span>
    </div>
  </VProgressCircular>
</template>
