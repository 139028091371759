import axios from 'axios'
import { auth } from '@/firebase'
import ErrorCodes from '@/common/constants/errorCodes'
import { useUserStore } from '@/stores/user'
import { useRootStore } from '@/stores/root'

const axiosIns = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  timeout: 30000,
  headers: {
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
})

axiosIns.interceptors.request.use(
  async (config) => {
    const token = await auth.currentUser?.getIdToken()
    if (token) {
      config.headers!.Authorization = `Bearer ${token}`
    }
    config.headers!['x-wl-portal-domain'] = (import.meta.env.VITE_CUSTOMER_APP_URL).replace(
        /^https?:\/\//,
        '',
    )
    return config
  },
  async (error) => {
    const userStore = useUserStore()
    if (error.response.status === 403) {
      await userStore.logout()
      if (window.location.pathname !== '/login') {
        window.location.reload()
      }
    }
    return Promise.reject(error)
  },
)
axiosIns.interceptors.response.use(
  (value) => {
    return Promise.resolve(value)
  },
  async (val) => {
    const rootStore = useRootStore()
    const userStore = useUserStore()
    if (val.code === ErrorCodes.axios.ERR_NETWORK) {
      rootStore.showSnackbar(
        'Error connecting to server. Please try again.',
        'error',
      )
    } else {
      if (val?.response?.status === 403) {
        await userStore.logout()
        if (window.location.pathname !== '/login') {
          window.location.reload()
        }
      }
      return Promise.reject(val)
    }
  },
)
export default axiosIns
