import axiosIns from '@axios'
import { DashboardKPIEnum } from '@/common/enums/dashboardKPIEnum'
import { DashboardGraphStatsEnum } from '@/common/enums/dashboardGraphStatsEnum'

export interface IDashboardStatsResponse {
  key: DashboardKPIEnum
  value: number
}

export interface IDashboardGraphStatsResponse {
  key: DashboardGraphStatsEnum
  value: {}
}

export default class DashboardService {
  static async getGeneralStats() {
    const { data } = await axiosIns.get('/admin/dashboard/stats')
    return data?.data
  }

  static async getRevenueHistoryGraphStats() {
    const { data } = await axiosIns.get('/admin/dashboard/revenue/graph')
    return data?.data
  }

  static async getDashboardStats(key: DashboardKPIEnum) {
    const { data } = await axiosIns.get(`/admin/dashboard/kpi/${key}`)
    return data?.data as IDashboardStatsResponse
  }

  static async getWPStats() {
    const { data } = await axiosIns.get('/admin/dashboard/wp-stats/graph')
    return data?.data
  }

  static async getGeneralWPStats() {
    const { data } = await axiosIns.get('/wp-details/admin/updatable/stats')
    return data?.data
  }

  static async getSitesByType(type: string) {
    const { data } = await axiosIns.get(
      `/admin/dashboard/graph-site-stats/${type}`,
    )
    return data?.data
  }

  static async getMRRStats() {
    const { data } = await axiosIns.get('/admin/dashboard/mrr/graph-stats')
    return data?.data
  }

  static async getDashboardGraphStats(key: DashboardGraphStatsEnum) {
    const { data } = await axiosIns.get(`/admin/dashboard/graph-stats/${key}`)
    return data?.data
  }

  static async getSiteRevenueStats() {
    const { data } = await axiosIns.get(
      '/admin/dashboard/site-revenue/graph-stats',
    )
    return data?.data
  }

  static async getDiskStats() {
    const { data } = await axiosIns.get(
      '/admin/dashboard/disk-revenue/graph-stats',
    )
    return data?.data
  }

  static async fetchPSIStats() {
    const res = await axiosIns.get('/admin/dashboard/psi-stats')
    return res?.data?.data
  }

  static async fetchPSIStatsGraph() {
    const res = await axiosIns.get('/admin/dashboard/psi-stats/graph')
    return res?.data?.data
  }

  static async fetchCurrentMrrPlanBreakdown() {
    const res = await axiosIns.get('/admin/dashboard/mrr/plan-breakdown')
    return res?.data?.data
  }

  static async fetchForcastedMrrPlanBreakdown() {
    const res = await axiosIns.get(
      '/admin/dashboard/forecasted/mrr/plan-breakdown',
    )
    return res?.data?.data
  }
}
