<script setup>
import { useSkins } from '@core/composable/useSkins'
import { useThemeConfig } from '@core/composable/useThemeConfig'

// @layouts plugin
import { AppContentLayoutNav } from '@layouts/enums'
import { subscribeToPusher } from '@/pusher/init'
import { useUserStore } from '@/stores/user'
import * as Sentry from '@sentry/browser'

const DefaultLayoutWithHorizontalNav = defineAsyncComponent(() =>
  import('./components/DefaultLayoutWithHorizontalNav.vue'),
)
const DefaultLayoutWithVerticalNav = defineAsyncComponent(() =>
  import('./components/DefaultLayoutWithVerticalNav.vue'),
)
const { width: windowWidth } = useWindowSize()
const { appContentLayoutNav, switchToVerticalNavOnLtOverlayNavBreakpoint } =
  useThemeConfig()

// Remove below composable usage if you are not using horizontal nav layout in your app
switchToVerticalNavOnLtOverlayNavBreakpoint(windowWidth)

const { layoutAttrs, injectSkinClasses } = useSkins()
const userStore = useUserStore()

Sentry.setUser({
  id: userStore.userProfile?.id,
  email: userStore.userProfile?.email,
})
injectSkinClasses()
onMounted(async () => {
  await subscribeToPusher(userStore.userProfile?.id)
})
</script>

<template>
  <template v-if="appContentLayoutNav === AppContentLayoutNav.Vertical">
    <DefaultLayoutWithVerticalNav v-bind="layoutAttrs" />
  </template>
  <template v-else>
    <DefaultLayoutWithHorizontalNav v-bind="layoutAttrs" />
  </template>
</template>

<style lang="scss">
// As we are using `layouts` plugin we need its styles to be imported
@use '@layouts/styles/default-layout';
</style>
