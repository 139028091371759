import * as Sentry from '@sentry/vue'
import router from '@/router'
import { CaptureConsole, HttpClient } from '@sentry/integrations'
import { App } from 'vue'

export const initSentry = (app: App<Element>, envsToExclude: string[]) => {
  if (envsToExclude.includes(import.meta.env.MODE)) {
    console.info('Sentry is disabled for this environment')
    return
  }
  Sentry.init({
    app,
    environment: import.meta.env.MODE,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    tracePropagationTargets: [
      'https://staging-engine.bionicwp.com',
      'https://bionic-prod-engine.bionicwp.com',
    ],
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
      new CaptureConsole({
        levels: ['error'],
      }),
      // HTTP calls integration
      new HttpClient(),
    ],
    // Performance Monitoring
    tracesSampleRate: import.meta.env.MODE === 'development' ? 1.0 : 0.6, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate:
      import.meta.env.MODE === 'development' ? 1.0 : 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}
