const CaseFilters = {
  snakeCaseToTitleCase: (input: string, delimiter: string): string => {
    const words = input.split(delimiter)
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1),
    )
    const titleCase = capitalizedWords.join(' ')
    return titleCase
  },
}

export default CaseFilters
