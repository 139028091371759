<script setup>
import VueApexCharts from 'vue3-apexcharts'
import { useTheme } from 'vuetify'
import { getLineChartSimpleConfig } from '@core/libs/apex-chart/apexCharConfig'
import { useDashboardStore } from '@/stores/dashboard'
import { useRootStore } from '@/stores/root'
import { ref } from 'vue'
import Filters from '@/filters'
import { formatNumbers, formatNumbersWithComma } from '@/utils/numeralHelper'

const dashboardStore = useDashboardStore()
const rootStore = useRootStore()

const vuetifyTheme = useTheme()
const balanceChartConfig = computed(() => {
  const current = computed(() => vuetifyTheme.current.value)
  const config = getLineChartSimpleConfig(current.value)
  const colors = computed(() => current.value.colors)
  config.colors = [colors.value.success, colors.value.info, colors.value.error]
  return config
})
const isLoading = ref(false)

const series = ref([
  {
    name: 'New Revenue',
    data: [],
  },
  {
    name: 'New Recurring Revenue',
    data: [],
  },
  {
    name: 'Lost Revenue',
    data: [],
  },
])

const getLostRevenueData = async () => {
  isLoading.value = true
  try {
    const data = await dashboardStore.getRevenueHistoryGraphStats()
    if (data) {
      balanceChartConfig.value.xaxis.categories = data.lostRevenue.categories
      balanceChartConfig.value.xaxis.labels.rotate = -20
      balanceChartConfig.value.xaxis.labels.rotateAlways = true
      balanceChartConfig.value.yaxis.labels.formatter = (value) => {
        return `$ ${formatNumbersWithComma(
          Filters.currency.centsToDollars(value ?? 0, 0),
        )}`
      }
      balanceChartConfig.value.dataLabels.formatter = (value) => {
        return `$ ${formatNumbersWithComma(
          Filters.currency.centsToDollars(value ?? 0, 0),
        )}`
      }

      balanceChartConfig.value.tooltip = {
        enabled: true,
        // custom(data) {
        //   return `<div class='bar-chart pa-2'>
        //             <span>${Filters.currency.centsToUSDString(
        //               data.series[data.seriesIndex][data.dataPointIndex]
        //             )}</span>
        //           </div>`;
        // },
      }
      series.value = [
        { name: 'New Revenue', data: data?.newRevenue?.data },
        {
          name: 'New Recurring Revenue',
          data: data?.newRecurringRevenue?.data,
        },
        { name: 'Lost Revenue', data: data?.lostRevenue?.data },
      ]
    }
  } catch (e) {
    rootStore.showSnackbar(
      'Error fetching lost-revenue graph data. Please contact support!',
      'error',
    )
  } finally {
    isLoading.value = false
  }
}

onMounted(() => {
  getLostRevenueData()
})
</script>
<template>
  <VCol cols="12">
    <VCard class="py-3">
      <VCardItem class="d-flex flex-wrap justify-space-between">
        <VCardTitle>Revenue History</VCardTitle>
      </VCardItem>
      <section v-if="!isLoading">
        <VueApexCharts
          type="line"
          height="400"
          :options="balanceChartConfig"
          :series="series"
        />
      </section>
      <section v-else style="min-height: 300px" class="text-center mt-4">
        <VProgressCircular indeterminate size="100" />
      </section>
    </VCard>
  </VCol>
</template>
