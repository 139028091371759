import vuePusher from '../plugins/vuePusher'
import { useRoute } from 'vue-router'
import { useSiteCancelsStore } from '@/stores/siteCancels'
import { ISiteCancel } from '@/models/ISiteCancel'
import { PusherEventsEnum } from '@/common/enums/pusherEventsEnum'
import { ICommissionPayout } from '@/models/commissionPayout'
import { useCommissionPayoutRequestsStore } from '@/stores/commissonPayoutRequests'

export const subscribeToPusher = async (userId?: number) => {
  try {
    const siteCancelsStore = useSiteCancelsStore()
    const payoutsStore = useCommissionPayoutRequestsStore()
    const route = useRoute()
    const channel = vuePusher.subscribe(`bionic-system-alerts`)
    channel.bind(
      PusherEventsEnum.ADMIN_COMMISSION_PAYOUT_REQUEST_DATA_UPDATED_EVENT,
      function (data: ISiteCancel) {
        if (route.name === 'site-cancels' && userId !== data.updated_by.id) {
          siteCancelsStore.updateSiteCancelOnPusherEvent(data)
        }
      },
    )
    channel.bind(
      PusherEventsEnum.ADMIN_COMMISSION_PAYOUT_REQUEST_DATA_UPDATED_EVENT,
      function (data: ICommissionPayout) {
        if (route.name === 'affiliate-payouts') {
          payoutsStore.updatePayoutRequestOnPusherEvent(data)
        }
      },
    )
  } catch (e) {
    console.error(e)
  }
}

export const unsubscribePusher = async (userId: number) => {
  try {
    vuePusher.unsubscribe(`bionic-system-alerts`)
  } catch (e) {
    console.error(e)
  }
}
