// @ts-ignore
import numeral from 'numeral'
// @ts-ignore
import commaNumber from 'comma-number'

export const formatNumbers = (val: any) => {
  return numeral(val).format('0.00a')
}

export const formatNumbersWithComma = (value: any) => {
  return commaNumber(value)
}
