import { defineStore } from 'pinia'
import { ICommissionPayout } from '@/models/commissionPayout'
import { AffiliateCommissionPayoutRequestStatusEnum } from '@/common/enums/affiliateCommissionPayoutRequestStatusEnum'

interface State {
  activeRequest: ICommissionPayout[]
  paidRequest: ICommissionPayout[]
}

export const useCommissionPayoutRequestsStore = defineStore(
  'commissionPayoutRequests',
  {
    state: (): State => {
      return {
        activeRequest: [],
        paidRequest: [],
      }
    },
    getters: {},
    actions: {
      async updatePayoutRequestOnPusherEvent(payoutReq: ICommissionPayout) {
        const existingSiteCancelIndex = this.activeRequest.findIndex(
          (sc) => sc.id === payoutReq.id,
        )
        if (existingSiteCancelIndex === -1) return
        if (
          payoutReq.status === AffiliateCommissionPayoutRequestStatusEnum.PAID
        ) {
          this.paidRequest.push(payoutReq)
          this.activeRequest.splice(existingSiteCancelIndex, 1)
        } else {
          this.activeRequest[existingSiteCancelIndex] = payoutReq
        }
      },
    },
  },
)
