import { defineStore } from 'pinia'
import dashboardService from '@/services/dashboardService'
import { DashboardKPIEnum } from '@/common/enums/dashboardKPIEnum'
import moment from 'moment'
import { DashboardGraphStatsEnum } from '@/common/enums/dashboardGraphStatsEnum'

export interface IStatsKPI {
  title: string
  color: string
  stats?: number | null
  icon: string
  key: DashboardKPIEnum
  action?: boolean
  to?: string
  isRevenue?: boolean
  isCommission?: boolean
}

export interface DashboardStoreState {
  stats: IStatsKPI[]
  hasStats: boolean
  sites: any
  revenue: any
  WPStats: any
  revenueHistoryStats: any
  WPStatsGraph: any
}

export const useDashboardStore = defineStore('dashboard', {
  state: (): DashboardStoreState => ({
    hasStats: false,
    stats: [
      {
        title: 'MRR',
        color: 'primary',
        stats: null,
        icon: 'tabler-currency-dollar',
        key: DashboardKPIEnum.MRR,
        isRevenue: true,
        action: true,
        to: '/billing/mrr/plan-breakdown',
      },
      {
        title: 'Forecasted MRR',
        color: 'warning',
        stats: null,
        icon: 'tabler-currency-dollar',
        key: DashboardKPIEnum.FORECASTED_MRR,
        isRevenue: true,
        action: true,
        to: '/billing/forecasted-mrr/plan-breakdown',
      },
      {
        title: 'New revenue',
        color: 'success',
        stats: null,
        icon: 'tabler-currency-dollar',
        key: DashboardKPIEnum.NEW_REVENUE,
        isRevenue: true,
      },
      {
        title: 'Lost Revenue',
        color: 'error',
        stats: null,
        icon: 'tabler-currency-dollar',
        key: DashboardKPIEnum.LOST_REVENUE,
        isRevenue: true,
      },
      {
        title: 'Total Commission',
        color: 'primary',
        stats: null,
        icon: 'tabler-currency-dollar',
        key: DashboardKPIEnum.TOTAL_COMMISSION,
        isCommission: true,
      },
      {
        title: 'UnPaid Commission',
        color: 'warning',
        stats: null,
        icon: 'tabler-currency-dollar',
        key: DashboardKPIEnum.UNPAID_COMMISSION,
        isCommission: true,
      },
      {
        title: 'Paid Commission',
        color: 'success',
        stats: null,
        icon: 'tabler-currency-dollar',
        key: DashboardKPIEnum.PAID_COMMISSION,
        isCommission: true,
      },
      {
        title: 'Due Commission',
        color: 'error',
        stats: null,
        icon: 'tabler-currency-dollar',
        key: DashboardKPIEnum.DUE_COMMISSION,
        isCommission: true,
      },
      {
        title: 'New Sites',
        color: 'success',
        stats: null,
        icon: 'tabler-world',
        key: DashboardKPIEnum.NEW_SITES,
        action: true,
        to: 'report?tab=general',
      },
      {
        title: 'Live Sites',
        color: 'primary',
        stats: null,
        icon: 'tabler-world',
        key: DashboardKPIEnum.LIVE_SITES,
      },
      {
        title: 'Deleted Sites',
        color: 'error',
        stats: null,
        icon: 'tabler-world',
        key: DashboardKPIEnum.DELETED_SITES,
      },
      {
        title: 'Paying Customers',
        color: 'primary',
        stats: null,
        icon: 'tabler-users',
        key: DashboardKPIEnum.PAYING_CUSTOMERS,
      },
      {
        title: 'Credits',
        color: 'info',
        stats: null,
        icon: 'tabler-currency-dollar',
        key: DashboardKPIEnum.CREDITS,
        isRevenue: true,
      },
      {
        title: 'Unpaid Amount',
        color: 'error',
        stats: null,
        icon: 'tabler-file',
        action: true,
        to: 'invoices?status=open',
        key: DashboardKPIEnum.UNPAID_AMOUNT,
        isRevenue: true,
      },
      // {
      //   title: "Projected MRR",
      //   color: "success",
      //   stats: null,
      //   icon: "tabler-currency-dollar",
      //   key: DashboardKPIEnum.PROJECTED_MRR,
      //   isRevenue: true,
      // },
      {
        title: 'Themes Updates',
        color: 'primary',
        stats: null,
        icon: 'mdi-wordpress',
        action: true,
        to: 'wordpress-updates?tab=themes',
        key: DashboardKPIEnum.THEME_UPDATES,
      },
      {
        title: 'Plugins Updates',
        color: 'primary',
        stats: null,
        icon: 'mdi-wordpress',
        action: true,
        to: 'wordpress-updates?tab=plugins',
        key: DashboardKPIEnum.PLUGIN_UPDATES,
      },
      {
        title: 'New WL Sites',
        color: 'success',
        stats: null,
        icon: 'tabler-world',
        key: DashboardKPIEnum.WL_NEW_SITES,
      },
      {
        title: 'Live WL Sites',
        color: 'primary',
        stats: null,
        icon: 'tabler-world',
        key: DashboardKPIEnum.WL_LIVE_SITES,
      },
      {
        title: 'Total WL Sites',
        color: 'primary',
        stats: null,
        icon: 'tabler-world',
        key: DashboardKPIEnum.WL_TOTAL_SITES,
      },
      {
        title: 'Deleted WL Sites',
        color: 'error',
        stats: null,
        icon: 'tabler-world',
        key: DashboardKPIEnum.WL_DELETED_SITES,
      },
      {
        title: 'Paying WL Customers',
        color: 'primary',
        stats: null,
        icon: 'tabler-users',
        key: DashboardKPIEnum.WL_PAYING_CUSTOMERS,
      },
    ],

    sites: [],
    revenue: [],
    WPStats: null,
    WPStatsGraph: null,
    revenueHistoryStats: null,
  }),
  getters: {
    getCategories() {
      let currentMonth = new Date().getMonth()
      let currentYear = new Date().getFullYear()
      let months = []

      for (let i = 0; i < 12; i++) {
        let m = currentMonth - i
        let y = currentYear
        if (m < 0) {
          m += 12
          y -= 1
        }
        months.push({ year: y, month: m })
      }
      return months
        .reverse()
        .map((month) =>
          moment(new Date(month.year, month.month).toISOString()).format(
            'MM/YY',
          ),
        )
    },
    getStatsByKey() {
      return (key: DashboardKPIEnum) => {
        return this.stats.find((s) => s.key === key)
      }
    },
  },
  actions: {
    async getSites(type: string) {
      const foundData = this.sites.find((s: any) => s.type === type)
      if (foundData) {
        return foundData.data
      }
      try {
        const data = await dashboardService.getSitesByType(type)
        this.sites.push({
          type,
          data: data?.[0],
        })
        return data?.[0]
      } catch (e) {
        return null
      }
    },
    async getRevenueStats(type: DashboardGraphStatsEnum) {
      const foundData = this.revenue.find((r: any) => r.type === type)
      if (foundData) {
        return foundData.data
      }
      return await this.getRevenueDataByType(type)
    },
    async getWPStats() {
      if (this.WPStats) {
        return this.WPStats
      }
      return await this.getWPStatsData()
    },
    async getRevenueHistoryGraphStats() {
      if (this.revenueHistoryStats) {
        return this.revenueHistoryStats
      }
      return await this._fetchRevenueHistoryGraphStats()
    },
    async getWPStatsGraph() {
      if (this.WPStatsGraph) {
        return this.WPStatsGraph
      }
      return await this.getWPStatsGraphData()
    },
    async getWPStatsData() {
      const data = await dashboardService.getWPStats()
      this.WPStats = data
      return this.WPStats
    },
    async _fetchRevenueHistoryGraphStats() {
      this.revenueHistoryStats =
        await dashboardService.getRevenueHistoryGraphStats()
      return this.revenueHistoryStats
    },
    async getWPStatsGraphData() {
      const data = await dashboardService.fetchPSIStatsGraph()
      this.WPStatsGraph = data
      return this.WPStatsGraph
    },
    getRevenueDataByType: async function (key: DashboardGraphStatsEnum) {
      const { value } = await dashboardService.getDashboardGraphStats(key)
      this.revenue.push({
        key,
        data: value,
      })
      return value
    },

    setStatsByKey(key: DashboardKPIEnum, value: number) {
      const foundData = this.stats.find((s) => s.key === key)
      if (foundData) {
        foundData.stats = value
      }
    },
  },
})
