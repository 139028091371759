import { defineStore } from 'pinia'
import { ISiteCancel } from '@/models/ISiteCancel'
import { CancelSiteStatusEnum } from '@/common/enums/siteCancelStatusEnum'

interface NotificationState {
  siteCancels: ISiteCancel[]
  doneSiteCancels: ISiteCancel[]
}

export const useSiteCancelsStore = defineStore('siteCancels', {
  state: (): NotificationState => {
    return {
      siteCancels: [],
      doneSiteCancels: [],
    }
  },
  getters: {},
  actions: {
    async updateSiteCancelOnPusherEvent(siteCancel: ISiteCancel) {
      const existingSiteCancelIndex = this.siteCancels.findIndex(
        (sc) => sc.id === siteCancel.id,
      )
      if (existingSiteCancelIndex === -1) return
      if (siteCancel.status === CancelSiteStatusEnum.DONE) {
        this.doneSiteCancels.push(siteCancel)
        this.siteCancels.splice(existingSiteCancelIndex, 1)
      } else {
        this.siteCancels[existingSiteCancelIndex] = siteCancel
      }
    },
  },
})
