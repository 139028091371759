<template>
  <div class="loader-component" id="loader-component">
    <div id="loading-bg">
      <div class="loading">
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
onMounted(() => {
  const loaderColor =
    localStorage.getItem('Materio-initial-loader-bg') || '#FFFFFF'
  const primaryColor =
    localStorage.getItem('Materio-initial-loader-color') || '#7367F0'

  if (loaderColor)
    document.documentElement.style.setProperty(
      '--initial-loader-bg',
      loaderColor,
    )
  if (primaryColor)
    document.documentElement.style.setProperty(
      '--initial-loader-color',
      primaryColor,
    )
})
</script>
<style scoped>
.loader-component {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vh;
  height: 100vh;
  z-index: 99999999;
}
#loading-bg {
  position: fixed;
  display: block;
  background: rgba(103, 116, 128, 0.4);

  block-size: 100%;
  inline-size: 100%;
}

.loading-logo {
  position: absolute;
  inset-block-start: 40%;
  inset-inline-start: calc(50% - 45px);
}

.loading {
  position: absolute;
  box-sizing: border-box;
  border: 3px solid transparent;
  block-size: 55px;
  border-radius: 50%;
  inline-size: 55px;
  inset-block-start: 50%;
  inset-inline-start: calc(50% - 35px);
}

.loading .effect-1,
.loading .effect-2,
.loading .effect-3 {
  position: absolute;
  box-sizing: border-box;
  border: 3px solid transparent;
  block-size: 100%;
  border-inline-start: 3px solid var(--initial-loader-color, #eee);
  border-radius: 50%;
  inline-size: 100%;
}

.loading .effect-1 {
  animation: rotate 1s ease infinite;
}

.loading .effect-2 {
  animation: rotate-opacity 1s ease infinite 0.1s;
}

.loading .effect-3 {
  animation: rotate-opacity 1s ease infinite 0.2s;
}

.loading .effects {
  transition: all 0.3s ease;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(1turn);
  }
}

@keyframes rotate-opacity {
  0% {
    opacity: 0.1;
    transform: rotate(0deg);
  }

  100% {
    opacity: 1;
    transform: rotate(1turn);
  }
}
</style>
