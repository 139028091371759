import { defineStore } from 'pinia'
import { generateId } from '@/utils/generators'

interface SnackbarOpts {
  showCloseButton?: boolean
  action?: {
    text: {
      success?: string
      error?: string
    }
    onActionSuccess?: () => void | null
    onActionError?: () => void | null
  }
}

interface ISnackbar {
  id: string
  show: boolean
  text: string
  color: string
  timeout: number
  opts?: SnackbarOpts
  icon?: string
}

interface RootStoreState {
  snackbars?: ISnackbar[]
  showFullscreenLoader: boolean
}

export const useRootStore = defineStore('root', {
  state: (): RootStoreState => ({
    snackbars: [],
    showFullscreenLoader: false,
  }),
  actions: {
    startFullscreenLoader() {
      this.showFullscreenLoader = true
    },
    endFullscreenLoader() {
      this.showFullscreenLoader = false
    },
    showSnackbar(
      text: string,
      color?: 'success' | 'info' | 'error' | 'primary' | 'secondary',
      timeout?: number,
      opts?: SnackbarOpts,
    ) {
      const snackbar: ISnackbar = {
        id: generateId(),
        show: true,
        text,
        color: color ?? 'success',
        timeout: timeout ?? 4000,
        opts,
      }
      if (color === 'error') {
        snackbar.icon = 'mdi-alert-circle'
      } else if (color === 'success') {
        snackbar.icon = 'mdi-check-circle'
      } else {
        snackbar.icon = 'mdi-information'
      }
      if (this.snackbars?.length && this.snackbars?.length > 1) {
        this.snackbars = this.snackbars.slice(1)
      }
      this.snackbars?.push(snackbar)
    },
    handleAPIException(error: any, alternateMessage?: string) {
      this.showSnackbar(
        error?.response?.data?.error?.[0] ||
          error?.response?.data?.data?.message ||
          error?.response?.data?.message ||
          alternateMessage ||
          'An error occurred',
        'error',
        6000,
      )
    },
  },
})
