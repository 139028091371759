<script setup>
import Revenue from '@/views/dashboard/revenue/Revenue.vue'
import { DashboardGraphStatsEnum } from '@/common/enums/dashboardGraphStatsEnum'

const openTab = ref(0)
const tabs = [
  {
    title: 'MRR',
    type: DashboardGraphStatsEnum.MRR,
    fullName: 'Monthly Recurring Revenue',
    colors: ['#FF9F43'],
  },
  {
    title: 'Sites revenue',
    type: DashboardGraphStatsEnum.SITE_REVENUE,
    colors: ['#FF9F43'],
  },
  {
    title: 'Commission',
    type: DashboardGraphStatsEnum.COMMISSION,
    fullName: 'Commission History',
    colors: ['#FF9F43', '#28C76F', '#EB1C2F', '#00CFE8'],
    isCommission: true,
    subTypes: [
      DashboardGraphStatsEnum.UNPAID_COMMISSION,
      DashboardGraphStatsEnum.PAID_COMMISSION,
      DashboardGraphStatsEnum.DUE_COMMISSION,
      DashboardGraphStatsEnum.TOTAL_COMMISSION,
    ],
  },
  {
    title: 'Disk / Edits revenue',
    type: DashboardGraphStatsEnum.DISK_REVENUE,
    colors: ['#FF9F43'],
  },
]
</script>
<template>
  <VCol cols="12">
    <VCard class="pt-3">
      <VTabs v-model="openTab" class="v-tabs-pill" align-tabs="center">
        <VTab v-for="tab in tabs" :key="tab.title">
          <span>{{ tab.title }}</span>
        </VTab>
      </VTabs>
      <VCardText>
        <VWindow v-model="openTab" class="disable-tab-transition">
          <VWindowItem v-for="tab in tabs" :key="tab.title + 'content'">
            <revenue
              :type="tab.type"
              :name="tab.title"
              :fullName="tab.fullName"
              :colors="tab.colors"
              :is-commission="tab.isCommission"
              :sub-types="tab.subTypes"
            />
          </VWindowItem>
        </VWindow>
      </VCardText>
    </VCard>
  </VCol>
</template>
