import { onAuthStateChanged } from 'firebase/auth'
import { auth } from '@/firebase'
import { useUserStore } from '@/stores/user'
import { getUser } from '@/services/userService'
import { User } from '@/models/user'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */

export const isUserLoggedIn = async () => {
  const store = useUserStore()
  const accessToken = await auth.currentUser?.getIdToken()
  if (accessToken) {
    return true
  } else {
    return await new Promise((resolve) => {
      const unsub = onAuthStateChanged(auth, (user) => {
        if (user) {
          store.setFirebaseUser(user)
          getUser()
            .then(async (userProfile: User) => {
              store.setUserProfile(userProfile)
              resolve(true)
            })
            .catch(() => {
              resolve(false)
              store.logout()
            })
        } else {
          store.setFirebaseUser(null)
          resolve(false)
        }
      })
      unsub()
    })
  }
}
