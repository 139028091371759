<script setup>
import VueApexCharts from 'vue3-apexcharts'
import { useTheme } from 'vuetify'
import { getLineChartSimpleConfig } from '@core/libs/apex-chart/apexCharConfig'
import { useDashboardStore } from '@/stores/dashboard'
import { useRootStore } from '@/stores/root'
import { ref } from 'vue'

const dashboardStore = useDashboardStore()
const rootStore = useRootStore()

const vuetifyTheme = useTheme()
const balanceChartConfig = computed(() => {
  const current = computed(() => vuetifyTheme.current.value)
  const config = getLineChartSimpleConfig(current.value)
  const colors = computed(() => current.value.colors)
  config.colors = [
    colors.value.primary,
    colors.value.success,
    colors.value.warning,
  ]
  return config
})
const isLoading = ref(false)

const series = ref([
  {
    name: 'Theme',
    data: [],
  },
  {
    name: 'Plugins',
    data: [],
  },
  {
    name: 'Core',
    data: [],
  },
])

const getWPData = async () => {
  isLoading.value = true
  try {
    const data = await dashboardStore.getWPStats()
    if (data) {
      balanceChartConfig.value.xaxis.categories = data.categories
      balanceChartConfig.value.tooltip = {
        enabled: true,
        y: {
          formatter: function (val) {
            return `<span class="text-capitalize">${val}</span>`
          },
        },
      }
      series.value = data?.data
    }
  } catch (e) {
    rootStore.showSnackbar(
      'Error in getting WP data, Please contact support!',
      'error',
    )
  } finally {
    isLoading.value = false
  }
}

onMounted(() => {
  getWPData()
})
</script>
<template>
  <VCol cols="12">
    <VCard class="py-3">
      <VCardItem class="d-flex flex-wrap justify-space-between">
        <VCardTitle>Theme/Plugin/Core Updates</VCardTitle>
      </VCardItem>
      <section v-if="!isLoading">
        <VueApexCharts
          type="line"
          height="400"
          :options="balanceChartConfig"
          :series="series"
        />
      </section>
      <section v-else style="min-height: 300px" class="text-center mt-4">
        <VProgressCircular indeterminate size="100" />
      </section>
    </VCard>
  </VCol>
</template>
