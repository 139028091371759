import { User as FireBaseUser } from 'firebase/auth'
import { auth } from '@/firebase'
import { User } from '@/models/user'
import { defineStore } from 'pinia'
import { unsubscribePusher } from '@/pusher/init'

interface UserState {
  firebaseUser: FireBaseUser | null
  userProfile: User | null
}

export const useUserStore = defineStore('user', {
  state: (): UserState => {
    return {
      firebaseUser: null,
      userProfile: null,
    }
  },
  getters: {
    async accessToken(): Promise<string | undefined> {
      const token = await this.firebaseUser?.getIdToken()
      return token
    },
    userId(): string | undefined {
      const uid = this.firebaseUser?.uid ?? undefined
      return uid
    },
    userDbId(): number | undefined {
      return this.userProfile?.id ?? undefined
    },
  },
  actions: {
    setFirebaseUser(firebaseUser: FireBaseUser | null) {
      this.firebaseUser = firebaseUser
    },
    refreshFirebaseUser() {
      this.firebaseUser = auth.currentUser
    },
    setUserProfile(userProfile: User) {
      this.userProfile = userProfile
    },
    async logout() {
      this.userProfile?.id && (await unsubscribePusher(this.userProfile.id))
      await auth.signOut()
      this.$reset()
    },
  },
})
