export enum DashboardGraphStatsEnum {
  MRR = 'mrr',
  DISK_REVENUE = 'disk_revenue',
  SITE_REVENUE = 'site_revenue',
  COMMISSION = 'commission',
  TOTAL_COMMISSION = 'total_commission',
  DUE_COMMISSION = 'due_commission',
  PAID_COMMISSION = 'paid_commission',
  UNPAID_COMMISSION = 'unpaid_commission',
}
