export enum DashboardKPIEnum {
  MRR = 'mrr',
  UNPAID_AMOUNT = 'unpaid-amount',
  CANCELLED_SITES = 'cancelled-sites',
  DELETED_SITES = 'deleted-sites',
  NEW_SITES = 'new-sites',
  LIVE_SITES = 'live-sites',
  NEW_REVENUE = 'new-revenue',
  TOTAL_CUSTOMERS = 'total-customers',
  PAYING_CUSTOMERS = 'paying-customers',
  CORE_UPDATES = 'core-updates',
  THEME_UPDATES = 'theme-updates',
  PLUGIN_UPDATES = 'plugin-updates',
  FORECASTED_MRR = 'forecasted_mrr',
  PROJECTED_MRR = 'projected_mrr',
  LOST_REVENUE = 'lost_revenue',
  CREDITS = 'credits',
  TOTAL_COMMISSION = 'total_commission',
  DUE_COMMISSION = 'due_commission',
  PAID_COMMISSION = 'paid_commission',
  UNPAID_COMMISSION = 'unpaid_commission',
  WL_NEW_SITES= 'wl-new-sites',
  WL_LIVE_SITES = 'wl-live-sites',
  WL_DELETED_SITES= 'wl-deleted-sites',
  WL_PAYING_CUSTOMERS = 'wl-paying-customers',
  WL_TOTAL_SITES = 'wl-total-sites'
}
