<script setup lang="ts">
import { useTheme } from 'vuetify'
import { useThemeConfig } from '@core/composable/useThemeConfig'
import { hexToRgb } from '@layouts/utils'
import { useRootStore } from '@/stores/root'
import FullscreenLoader from '@/components/FullscreenLoader.vue'
import { computed, watchEffect } from 'vue'

const rootStore = useRootStore()
const snackbars = computed(() => rootStore.snackbars)
const showFullscreenLoader = computed(() => rootStore.showFullscreenLoader)

const {
  syncInitialLoaderTheme,
  syncVuetifyThemeWithTheme: syncConfigThemeWithVuetifyTheme,
  isAppRtl,
} = useThemeConfig()
const { global } = useTheme()

// ℹ️ Sync current theme with initial loader theme
syncInitialLoaderTheme()
syncConfigThemeWithVuetifyTheme()
</script>

<template>
  <VLocaleProvider :rtl="isAppRtl">
    <!-- ℹ️ This is required to set the background color of active nav link based on currently active global theme's primary -->
    <VApp
      :style="`--v-global-theme-primary: ${hexToRgb(
        global.current.value.colors.primary,
      )}`"
    >
      <div
        v-for="(snackbar, index) in snackbars"
        :key="snackbar.id"
        class="d-flex align-center"
      >
        <VSnackbar
          v-model="snackbar.show"
          transition="slide-x-transition"
          :color="snackbar.color"
          :timeout="snackbar.timeout"
          position="absolute"
          max-height="70"
          :style="'margin-top:' + 72 * index + 'px'"
          location="top right"
          variant="elevated"
          shaped
          multi-line
          close-on-back
          close-on-content-click
          @update:model-value="
            snackbars = snackbars?.splice(snackbars?.indexOf(snackbar), 1)
          "
        >
          <div class="d-flex flex-row align-center">
            <div class="mr-2">
              <VIcon size="25" :icon="snackbar.icon" />
            </div>
            <div>
              <span v-html="snackbar.text" />
            </div>
          </div>
          <template v-if="snackbar.opts?.action" #actions>
            <VBtn
              elevation="20"
              color="white"
              size="small"
              variant="flat"
              style="color: #000"
              @click="
                snackbar.color === 'success'
                  ? snackbar.opts?.action?.onActionSuccess()
                  : snackbar.opts?.action?.onActionError()
              "
            >
              {{
                snackbar.color === 'success'
                  ? snackbar.opts?.action?.text.success
                  : snackbar.opts?.action?.text.error
              }}
            </VBtn>
          </template>
        </VSnackbar>
      </div>
      <FullscreenLoader
        v-if="showFullscreenLoader"
        id="root-fullscreen-loader"
      />
      <RouterView />
    </VApp>
  </VLocaleProvider>
</template>
