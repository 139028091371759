<script setup lang="ts">
import { computed, ref } from 'vue'
import { useDashboardStore } from '@/stores/dashboard'

const dashboardStore = useDashboardStore()
const statisticsVerticalSimple = computed(() => dashboardStore.stats.filter(stat=> !stat.key.startsWith('wl-')))
const statisticsWlVerticalSimple = computed(() => dashboardStore.stats.filter(stat=> stat.key.startsWith('wl-')))

const openTab = ref(0)
const tabs = [
  {
    title: 'Bionic',
    key:'bionic'
  },
  {
    title: 'WhiteLabel',
    key:'whitelabel'
  },
]
</script>
<template>
    <VCol cols="12">
      <VTabs v-model="openTab" class="v-tabs-pill" align-tabs="center">
        <VTab v-for="tab in tabs" :key="tab.key">
          <span>{{ tab.title }}</span>
        </VTab>
      </VTabs>
      <VWindow v-model="openTab" class="mt-6" >
        <VWindowItem key="bionic">
          <VRow>
            <VCol
                v-for="(statistics, index) in statisticsVerticalSimple"
                :key="index"
                md="3"
                cols="6"
            >
              <CardStatisticsVerticalSimple
                  v-bind="statistics"
                  :card-key="statistics.key"
              />
            </VCol>
          </VRow>
        </VWindowItem>
        <VWindowItem key="whiteLabel">
          <VRow>
            <VCol
                v-for="(statistics, index) in statisticsWlVerticalSimple"
                :key="index"
                md="3"
                cols="6"
            >
              <CardStatisticsVerticalSimple
                  v-bind="statistics"
                  :card-key="statistics.key"
              />
            </VCol>
          </VRow>
        </VWindowItem>
      </VWindow>
    </VCol>

</template>
