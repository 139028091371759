/* eslint-disable */

import "@/@fake-db/db";
import "@/@iconify/icons-bundle";
import App from "@/App.vue";
import ability from "@/plugins/casl/ability";
import layoutsPlugin from "@/plugins/layouts";
import vuetify from "@/plugins/vuetify";
import {loadFonts} from "@/plugins/webfontloader";
import "@/styles/styles.scss";
import "@core/scss/template/index.scss";
import {abilitiesPlugin} from "@casl/vue";
import {createPinia} from "pinia";
import {createApp} from "vue";
import PiniaPersistedStatePlugin from "pinia-plugin-persistedstate";
import router from "@/router";
import VueChatScroll from 'vue3-chat-scroll-next'
import {initSentry} from "@/plugins/sentry";
import './assets/common-styles.scss'

loadFonts();

// Create vue app
const app = createApp(App);
// Setup Plugins
const pinia = createPinia();
pinia.use(PiniaPersistedStatePlugin);
pinia.use(({store}) => {
    store.$pinia = markRaw(pinia);
    store.$router = markRaw(router);
});
// Use plugins
app.use(vuetify);
app.use(pinia);
app.use(router);
app.use(layoutsPlugin);
app.use(VueChatScroll)
app.use(abilitiesPlugin, ability, {
    useGlobalProperties: false,
});
// Sentry Error Tracking
initSentry(app, ["development"]);
// Mount vue app
app.mount("#app");
