<script setup>
import VueApexCharts from 'vue3-apexcharts'
import { useTheme } from 'vuetify'
import { getLineChartSimpleConfig } from '@core/libs/apex-chart/apexCharConfig'
import { useDashboardStore } from '@/stores/dashboard'
import { useRootStore } from '@/stores/root'
import { ref } from 'vue'

const dashboardStore = useDashboardStore()
const rootStore = useRootStore()

const props = defineProps({
  type: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
})

const isLoading = ref(false)

const vuetifyTheme = useTheme()
const balanceChartConfig = computed(() =>
  getLineChartSimpleConfig(vuetifyTheme.current.value),
)

const series = reactive([
  {
    data: [],
  },
])

const getSitesData = async () => {
  isLoading.value = true
  try {
    const data = await dashboardStore.getSites(props.type)
    if (data) {
      const categories = dashboardStore.getCategories
      balanceChartConfig.value.xaxis.categories = categories
      balanceChartConfig.value.xaxis.labels.rotate = -20
      balanceChartConfig.value.tooltip = {
        enabled: true,
        y: {
          formatter: function (val) {
            return `<span class="text-capitalize">${val}</span>`
          },
        },
      }
      const seriesData = []
      for (let category of categories) {
        const foundIndex = data?.categories?.findIndex((c) => c === category)
        if (foundIndex < 0) {
          seriesData.push(0)
        } else {
          seriesData.push(data?.data?.[foundIndex] ?? 0)
        }
      }
      series[0].name = props.name
      series[0].data = seriesData
    }
  } catch (e) {
    rootStore.showSnackbar(
      'Error in getting sites data, Please contact support!',
      'error',
    )
  } finally {
    isLoading.value = false
  }
}

onMounted(() => {
  getSitesData()
})
</script>
<template>
  <VCardItem class="d-flex flex-wrap justify-space-between">
    <VCardTitle>{{ name }}</VCardTitle>
  </VCardItem>
  <section v-if="!isLoading">
    <VueApexCharts
      type="line"
      height="400"
      :options="balanceChartConfig"
      :series="series"
    />
  </section>
  <section v-else style="min-height: 300px" class="text-center mt-4">
    <VProgressCircular indeterminate size="100" />
  </section>
</template>
