<script setup lang="ts">
import { onMounted, ref } from 'vue'
import DashboardStatsService from '@/services/dashboardService'
import SpeedChart from '@/views/charts/sites/speedChart.vue'
import { useRootStore } from '@/stores/root'
import { useRouter } from 'vue-router'
import { SitePsiEnum } from '../../common/enums/sitePSIEnum'

interface IPsiStatsHistorySingleDevice {
  above_threshold: {
    page_speed: number
    load_time: number
  }
  below_threshold: {
    page_speed: number
    load_time: number
  }
}

interface PSIStatsData {
  mobile: IPsiStatsHistorySingleDevice
  desktop: IPsiStatsHistorySingleDevice
  combined: {
    above_threshold: {
      ttfb: number
    }
    below_threshold: {
      ttfb: number
    }
  }
  total_sites: number
}

const loading = ref(false)
const data = ref<PSIStatsData>()
const rootStore = useRootStore()
const router = useRouter()
const getPsiStats = async () => {
  try {
    loading.value = true
    const res = await DashboardStatsService.fetchPSIStats()
    data.value = res
  } catch (error) {
    rootStore.showSnackbar('Could not fetch PSI stats', 'error')
  } finally {
    loading.value = false
  }
}

const plainNumberToPercentage = (number: number): number | string => {
  return data.value?.total_sites
    ? (number / data.value.total_sites) * 100
    : '--'
}

//:to="{name: 'sites-psi', query: {strategy: 'desktop', is_above: 'true', metric: 'page_speed_score'}}"
const gotoPSISitesPage = (
  strategy: 'mobile' | 'desktop' | 'combined',
  metric: 'page_speed_score' | 'load_time' | 'ttfb',
  is_above: boolean,
  orderBy?: string,
  orderDir?: 'asc' | 'desc',
) => {
  router.push({
    name: 'sites-psi',
    query: {
      strategy,
      metric,
      is_above: is_above ? 'yes' : 'no',
      orderBy,
      orderDir,
    },
  })
}

onMounted(() => {
  getPsiStats()
})
</script>
<template>
  <VCard title="Site PageSpeed Insights" :loading="loading">
    <VRow>
      <VCol>
        <VCardText>
          <h6 class="text-lg float-left text-no-wrap font-weight-semibold">
            Desktop:
          </h6>
        </VCardText>
      </VCol>
    </VRow>

    <VCardText class="pt-6">
      <VRow>
        <VCol
          cols="3"
          md="3"
          class="d-flex justify-center align-center flex-column"
        >
          <VCard
            class="pa-6 card-custom"
            :style="`cursor: ${
              data?.desktop.above_threshold.page_speed !== 0
                ? 'pointer'
                : 'not-allowed'
            }`"
            @click="
              data?.desktop.above_threshold.page_speed !== 0 &&
                gotoPSISitesPage(
                  'desktop',
                  'page_speed_score',
                  true,
                  'siteAfterPSI.desktop_page_speed_score',
                  'asc',
                )
            "
          >
            <span class="text-sm-body-2"> Above 85 page speed </span>
            <speed-chart
              label="Sites"
              :label-value="data?.desktop.above_threshold.page_speed"
              color="success"
              :value="plainNumberToPercentage(data?.desktop.above_threshold.page_speed!)"
            />
          </VCard>
        </VCol>
        <VCol
          cols="3"
          md="3"
          class="d-flex justify-center align-center flex-column"
        >
          <VCard
            class="pa-6 card-custom"
            align="center"
            :style="`cursor: ${
              data?.desktop.below_threshold.page_speed !== 0
                ? 'pointer'
                : 'not-allowed'
            }`"
            @click="
              data?.desktop.below_threshold.page_speed !== 0 &&
                gotoPSISitesPage(
                  'desktop',
                  'page_speed_score',
                  false,
                  'siteAfterPSI.desktop_page_speed_score',
                  'asc',
                )
            "
          >
            <span class="text-sm-body-2"> Below 85 page speed </span>
            <speed-chart
              label="Sites"
              color="error"
              :label-value="data?.desktop.below_threshold.page_speed"
              :value="plainNumberToPercentage(data?.desktop.below_threshold.page_speed!)"
            />
          </VCard>
        </VCol>
        <VCol
          cols="3"
          md="3"
          class="d-flex justify-center align-center flex-column"
        >
          <VCard
            class="pa-6 card-custom"
            align="center"
            :style="`cursor: ${
              data?.desktop.below_threshold.load_time !== 0
                ? 'pointer'
                : 'not-allowed'
            }`"
            @click="
              data?.desktop.below_threshold.load_time !== 0 &&
                gotoPSISitesPage(
                  'desktop',
                  'load_time',
                  false,
                  'siteAfterPSI.desktop_load_time',
                  'desc',
                )
            "
          >
            <span class="text-sm-body-2"> Below 3sec load time </span>
            <speed-chart
              label="Sites"
              color="success"
              :label-value="data?.desktop.below_threshold.load_time"
              :value="plainNumberToPercentage(data?.desktop.below_threshold.load_time!)"
            />
          </VCard>
        </VCol>
        <VCol
          cols="3"
          md="3"
          class="d-flex justify-center align-center flex-column"
        >
          <VCard
            class="pa-6 card-custom"
            align="center"
            :style="`cursor: ${
              data?.desktop.above_threshold.load_time !== 0
                ? 'pointer'
                : 'not-allowed'
            }`"
            @click="
              data?.desktop.above_threshold.load_time !== 0 &&
                gotoPSISitesPage(
                  'desktop',
                  'load_time',
                  true,
                  'siteAfterPSI.desktop_load_time',
                  'desc',
                )
            "
          >
            <span class="text-sm-body-2"> Above 3sec load time </span>
            <speed-chart
              label="Sites"
              color="error"
              :label-value="data?.desktop.above_threshold.load_time"
              :value="plainNumberToPercentage(data?.desktop.above_threshold.load_time!)"
            />
          </VCard>
        </VCol>
      </VRow>
    </VCardText>
    <!--  Mobile PSI  -->
    <VDivider class="my-4" />
    <VRow>
      <VCol>
        <VCardText>
          <h6 class="text-lg float-left text-no-wrap font-weight-semibold">
            Mobile:
          </h6>
        </VCardText>
      </VCol>
    </VRow>

    <VCardText>
      <VRow>
        <VCol
          cols="3"
          md="3"
          class="d-flex justify-center align-center flex-column"
        >
          <VCard
            class="pa-6 card-custom"
            align="center"
            :style="`cursor: ${
              data?.mobile.above_threshold.page_speed !== 0
                ? 'pointer'
                : 'not-allowed'
            }`"
            @click="
              data?.mobile.above_threshold.page_speed !== 0 &&
                gotoPSISitesPage(
                  'mobile',
                  'page_speed_score',
                  true,
                  'siteAfterPSI.mobile_page_speed_score',
                  'asc',
                )
            "
          >
            <span class="text-sm-body-2"> Above 80 page speed </span>
            <speed-chart
              label="Sites"
              :label-value="data?.mobile?.above_threshold.page_speed"
              color="success"
              :value="plainNumberToPercentage(data?.mobile?.above_threshold.page_speed!)"
            />
          </VCard>
        </VCol>
        <VCol
          cols="3"
          md="3"
          class="d-flex justify-center align-center flex-column"
        >
          <VCard
            class="pa-6 card-custom"
            align="center"
            :style="`cursor: ${
              data?.mobile.below_threshold.page_speed !== 0
                ? 'pointer'
                : 'not-allowed'
            }`"
            @click="
              data?.mobile.below_threshold.page_speed !== 0 &&
                gotoPSISitesPage(
                  'mobile',
                  'page_speed_score',
                  false,
                  'siteAfterPSI.mobile_page_speed_score',
                  'asc',
                )
            "
          >
            <span class="text-sm-body-2"> Below 80 page speed </span>
            <speed-chart
              label="Sites"
              color="error"
              :label-value="data?.mobile?.below_threshold.page_speed"
              :value="plainNumberToPercentage(data?.mobile?.below_threshold.page_speed!)"
            />
          </VCard>
        </VCol>
        <VCol
          cols="3"
          md="3"
          class="d-flex justify-center align-center flex-column"
        >
          <VCard
            class="pa-6 card-custom"
            align="center"
            :style="`cursor: ${
              data?.mobile.below_threshold.load_time !== 0
                ? 'pointer'
                : 'not-allowed'
            }`"
            @click="
              data?.mobile.below_threshold.load_time !== 0 &&
                gotoPSISitesPage(
                  'mobile',
                  'load_time',
                  false,
                  'siteAfterPSI.mobile_load_time',
                  'desc',
                )
            "
          >
            <span class="text-sm-body-2"> Below 3.5sec load time </span>
            <speed-chart
              label="Sites"
              color="success"
              :label-value="data?.mobile?.below_threshold.load_time"
              :value="plainNumberToPercentage(data?.mobile?.below_threshold.load_time!)"
            />
          </VCard>
        </VCol>
        <VCol
          cols="3"
          md="3"
          class="d-flex justify-center align-center flex-column"
        >
          <VCard
            class="pa-6 card-custom"
            align="center"
            :style="`cursor: ${
              data?.mobile.above_threshold.load_time !== 0
                ? 'pointer'
                : 'not-allowed'
            }`"
            @click="
              data?.mobile.above_threshold.load_time !== 0 &&
                gotoPSISitesPage(
                  'mobile',
                  'load_time',
                  true,
                  'siteAfterPSI.mobile_load_time',
                  'desc',
                )
            "
          >
            <span class="text-sm-body-2"> Above 3.5sec load time </span>
            <speed-chart
              label="Sites"
              color="error"
              :label-value="data?.mobile?.above_threshold.load_time"
              :value="plainNumberToPercentage(data?.mobile?.above_threshold.load_time!)"
            />
          </VCard>
        </VCol>
      </VRow>
    </VCardText>

    <!--    &lt;!&ndash;  Combined PSI  &ndash;&gt;-->
    <!--    <VDivider class="my-4" />-->
    <!--    <VRow>-->
    <!--      <VCol>-->
    <!--        <VCardText>-->
    <!--          <h6 class="text-lg float-left text-no-wrap font-weight-semibold">-->
    <!--            Combined (Mobile & Desktop):-->
    <!--          </h6>-->
    <!--        </VCardText>-->
    <!--      </VCol>-->
    <!--    </VRow>-->
    <!--    <VCardText>-->
    <!--      <VRow-->
    <!--        >.-->
    <!--        <VCol-->
    <!--          cols="3"-->
    <!--          md="3"-->
    <!--          class="d-flex justify-center align-center flex-column"-->
    <!--        >-->
    <!--          <VCard-->
    <!--            class="pa-6 card-custom"-->
    <!--            align="center"-->
    <!--            :style="`cursor: ${-->
    <!--              data?.combined.below_threshold.ttfb !== 0-->
    <!--                ? 'pointer'-->
    <!--                : 'not-allowed'-->
    <!--            }`"-->
    <!--            @click="-->
    <!--              data?.combined.below_threshold.ttfb !== 0 &&-->
    <!--                gotoPSISitesPage(-->
    <!--                  'combined',-->
    <!--                  'ttfb',-->
    <!--                  false,-->
    <!--                  'siteAfterPSI.ttfb',-->
    <!--                  'desc',-->
    <!--                )-->
    <!--            "-->
    <!--          >-->
    <!--            <span class="text-sm-body-2">-->
    <!--              TTFB Below {{ SitePsiEnum.TTFB_REFERENCE_VALUE + 'ms' }}-->
    <!--            </span>-->
    <!--            <speed-chart-->
    <!--              label="Sites"-->
    <!--              color="success"-->
    <!--              :label-value="data?.combined.below_threshold.ttfb"-->
    <!--              :value="plainNumberToPercentage(data?.combined.below_threshold.ttfb!)"-->
    <!--            />-->
    <!--          </VCard>-->
    <!--        </VCol>-->
    <!--        <VCol-->
    <!--          cols="3"-->
    <!--          md="3"-->
    <!--          class="d-flex justify-center align-center flex-column"-->
    <!--        >-->
    <!--          <VCard-->
    <!--            class="pa-6 card-custom"-->
    <!--            align="center"-->
    <!--            :style="`cursor: ${-->
    <!--              data?.combined.above_threshold.ttfb !== 0-->
    <!--                ? 'pointer'-->
    <!--                : 'not-allowed'-->
    <!--            }`"-->
    <!--            @click="-->
    <!--              data?.combined.above_threshold.ttfb !== 0 &&-->
    <!--                gotoPSISitesPage(-->
    <!--                  'combined',-->
    <!--                  'ttfb',-->
    <!--                  true,-->
    <!--                  'siteAfterPSI.ttfb',-->
    <!--                  'desc',-->
    <!--                )-->
    <!--            "-->
    <!--          >-->
    <!--            <span class="text-sm-body-2">-->
    <!--              TTFB Above {{ SitePsiEnum.TTFB_REFERENCE_VALUE + 'ms' }}-->
    <!--            </span>-->
    <!--            <speed-chart-->
    <!--              label="Sites"-->
    <!--              :label-value="data?.combined.above_threshold.ttfb"-->
    <!--              color="error"-->
    <!--              :value="plainNumberToPercentage(data?.combined.above_threshold.ttfb!)"-->
    <!--            />-->
    <!--          </VCard>-->
    <!--        </VCol>-->
    <!--      </VRow>-->
    <!--    </VCardText>-->
  </VCard>
</template>
<style lang="scss">
.card-custom {
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  &:hover {
    cursor: pointer;
    background-color: #002544;
    // give it a little elevation effect
    color: #fff;
  }
}
</style>
